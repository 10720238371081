import React, {RefObject} from 'react';
import s from './AddToCartButton.scss';
import {DataHook} from '../ProductItem';
import {ButtonWidthType, IProduct} from '../../../../types/galleryTypes';
import classNames from 'classnames';
import {withGlobals} from '../../../../globalPropsContext';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {StatesButton, StatesButtonStates} from 'wix-ui-tpa';
import {classes as addToCartStyle, st as addToCartStyles} from './AddToCartButton.st.css';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';
import {ActionStatus} from '@wix/wixstores-client-core/dist/es/src/constants';

export interface IAddToCart extends IGalleryGlobalProps {
  index: number;
  product: IProduct;
  quantity: number;
  showOnHoverClassName: string;
  onAddToCartClicked(): void;
}

export class AddToCartComp extends React.Component<IAddToCart> {
  public addToCartButtonRef: RefObject<StatesButton> = React.createRef();

  private get isAddToCartEnabled() {
    const {
      globals: {
        experiments: {isPreOrderEnabled},
      },
    } = this.props;
    const currentState = this.props.globals.productsManifest[this.props.product.id].addToCartState;
    const isEnabledState = currentState === AddToCartState.ENABLED;
    const isPreOrderState = isPreOrderEnabled && currentState === AddToCartState.PRE_ORDER;
    return isEnabledState || isPreOrderState;
  }

  private readonly handleAddToCartButtonClick = () => {
    const {
      globals: {handleAddToCart},
      product: {id: productId},
      index,
      quantity,
      onAddToCartClicked,
    } = this.props;

    handleAddToCart({productId, index, quantity});
    onAddToCartClicked();
  };

  private readonly handleNotificationEnd = () => {
    this.props.globals.updateAddToCartStatus(this.props.product.id, ActionStatus.IDLE);
  };

  private get addToCartText(): string {
    const {
      globals: {
        experiments: {isPreOrderEnabled},
      },
    } = this.props;
    const {
      galleryAddToCartButtonText,
      addToCartContactSeller,
      addToCartOutOfStock,
      galleryAddToCartPreOrderButtonText,
    } = this.props.globals.textsMap;
    const {addToCartState} = this.props.globals.productsManifest[this.props.product.id];

    if (addToCartState === AddToCartState.DISABLED) {
      return addToCartContactSeller;
    }
    if (addToCartState === AddToCartState.OUT_OF_STOCK) {
      return addToCartOutOfStock;
    }
    if (isPreOrderEnabled && addToCartState === AddToCartState.PRE_ORDER) {
      return galleryAddToCartPreOrderButtonText;
    }
    return galleryAddToCartButtonText;
  }

  private get addToCartAnimationState(): StatesButtonStates {
    const {addedToCartStatus, shouldShowAddToCartSuccessAnimation} = this.props.globals;
    const isAddedSuccessfully = addedToCartStatus[this.props.product.id] === ActionStatus.SUCCESSFUL;
    if (shouldShowAddToCartSuccessAnimation && isAddedSuccessfully) {
      return StatesButtonStates.SUCCESS;
    }
    return StatesButtonStates.IDLE;
  }

  public render() {
    const {
      shouldShowMobile,
      experiments: {shouldUseGalleryMobileSettingsInViewer},
      styleParams: {
        booleans: {gallery_addToCartButtonShowOnHover: shouldShowOnHover},
        fonts: {
          gallery_addToCartButtonWidth: {value: buttonWidth},
        },
      },
    } = this.props.globals;

    const {showOnHoverClassName} = this.props;

    return (
      <div className={s.addToCartBtnContainer}>
        <StatesButton
          state={this.addToCartAnimationState}
          idleContent={this.addToCartText}
          onNotificationEnd={this.handleNotificationEnd}
          ref={this.addToCartButtonRef}
          onClick={this.handleAddToCartButtonClick}
          disabled={!this.isAddToCartEnabled}
          className={classNames(
            s.addToCartBtn,
            addToCartStyles(addToCartStyle.addToCartButton, {
              useMobileFont: shouldUseGalleryMobileSettingsInViewer && shouldShowMobile,
            }),
            {
              [showOnHoverClassName]: shouldShowOnHover && !shouldShowMobile,
              [s.fitWidth]: buttonWidth === ButtonWidthType.FIT,
              [s.stretchWidth]: buttonWidth === ButtonWidthType.STRETCH,
            }
          )}
          fullWidth
          data-hook={DataHook.AddToCartButton}
        />
      </div>
    );
  }
}

export const AddToCartButton = withGlobals(withTranslations()(AddToCartComp));
