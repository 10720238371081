/* istanbul ignore file: todo tests @titk */

/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/unbound-method */
import React, {CSSProperties, RefObject} from 'react';
import _ from 'lodash';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import {classes as addToCartStyle, st as addToCartStyles} from './AddToCartButton.st.css';
import {classes as quantityStyle, style as styleQuantity} from './QuantityCounter.st.css';
import autobind from 'auto-bind-es5';
import classNames from 'classnames';
import stylesOld from './ProductItemOld.scss';
import stylesNew from './ProductItem.scss';
import {classes as productItemStylable, st as productItemStyles} from './ProductItem.st.css';
import {Counter, StatesButton, StatesButtonStates, Text} from 'wix-ui-tpa';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {Alignments, ImagePositions, IProduct, RibbonPlacementId, VerticalAlignments} from '../../../types/galleryTypes';
import {ProductImageOLD} from './ProductImage/ProductImageOLD';
import {ProductPrice} from './ProductPrice/ProductPrice';
import {QuantityCalculator} from '@wix/wixstores-client-core/dist/es/src/quantity-calculator/quantityCalculator';
import {withGlobals} from '../../../globalPropsContext';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {ActionStatus} from '@wix/wixstores-client-core/dist/es/src/constants';
import {ProductOptionsChangeData, ProductOptionsWithGlobals} from './ProductOptions/ProductOptions';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';
import {ProductOptionType} from '@wix/wixstores-graphql-schema';
import {ProductMedia} from './ProductImage/ProductMedia';
import {Ribbon as ProductRibbon} from './Ribbon/Ribbon';
import {AddToCartButton as AddToCart} from './AddToCartButton/AddToCartButton';

export enum DataHook {
  AddToCartButton = 'product-item-add-to-cart-button',
  LineBetweenNameAndPrice = 'product-item-line-between-name-and-price',
  LinkContainer = 'product-item-container',
  Name = 'product-item-name',
  Price = 'product-item-price',
  ProductDetails = 'product-item-product-details',
  ProductDetailsLink = 'product-item-product-details-link',
  QuantityCounter = 'product-item-quantity-counter',
  QuantityCounterWrapper = 'product-item-quantity-counter-wrapper',
  QuickViewButton = 'product-item-quick-view-button',
  Ribbon = 'product-item-ribbon',
  RibbonNew = 'product-item-ribbon-new',
  Root = 'product-item-root',
  SrOnlyName = 'product-item-sr-only-name',
  Options = 'product-item-options',
  NotImageContainer = 'not-image-container',
}

export interface IProductItemProps extends IGalleryGlobalProps {
  a11yAnnouncer: Announcer;
  index: number;
  product: IProduct;
  innerRef?: Function;
  disabled?: boolean;
  style?: CSSProperties;
}

interface IProductItemState {
  errors: {quantity?: string};
  limits: {quantity: {min: number; max: number}};
  selections: number[];
  quantity: number;
  showHoverPreview: boolean;
  isHovered: boolean;
}

const PREVIEW_DURATION = 1000;

export class ProductItem extends React.Component<IProductItemProps & IProvidedTranslationProps, IProductItemState> {
  private readonly debouncedStopHoverPreview = _.debounce(
    () => this.setState({showHoverPreview: false}),
    PREVIEW_DURATION
  );
  private readonly productLink = React.createRef<HTMLAnchorElement>();
  public addToCartButtonRef: RefObject<StatesButton> = React.createRef();
  public state: IProductItemState = {
    isHovered: false,
    showHoverPreview: false,
    quantity: 1,
    selections: [],
    errors: {quantity: undefined},
    limits: {
      quantity: {
        min: 1,
        max: 1,
      },
    },
  };

  private readonly s = stylesOld;

  constructor(props) {
    super(props);
    if (props.globals.experiments.galleryProductItemNewComposition) {
      this.s = stylesNew;
    }
    autobind(this);
  }

  public static getDerivedStateFromProps(props: IProductItemProps): Partial<IProductItemState> {
    const inventoryRange = QuantityCalculator.getQuantitiesRange(props.product);
    const inventoryCount = inventoryRange[inventoryRange.length - 1];

    return {
      limits: {
        quantity: {
          min: 1,
          max: inventoryCount,
        },
      },
    };
  }

  public focus(): void {
    this.productLink.current.focus();
  }

  private renderSrOnlyName() {
    return (
      <span data-hook={DataHook.SrOnlyName} className={a11y.srOnly}>
        {this.props.product.name}
      </span>
    );
  }

  private renderName() {
    const {
      shouldShowMobile,
      experiments: {shouldUseGalleryMobileSettingsInViewer, fixLongProductNameOverflow},
    } = this.props.globals;
    const htmlTag = this.props.globals.htmlTags.productNameHtmlTag;
    const classes = classNames(
      productItemStyles(productItemStylable.root, {
        useMobileFont: shouldUseGalleryMobileSettingsInViewer && shouldShowMobile,
      }),
      this.s.productName,
      {[this.s.fixProductNameOverflow]: fixLongProductNameOverflow}
    );

    return (
      <Text tagName={htmlTag} className={classes} data-hook={DataHook.Name}>
        {this.props.product.name}
      </Text>
    );
  }

  private renderImage() {
    const {
      globals: {
        shouldShowMobile,
        imageMode,
        imageRatio,
        styleParams: {
          booleans: {showQuickView: shouldShowQuickViewButton},
          fonts: {
            gallery_hoverType: {value: hoverType},
          },
          numbers: {
            galleryImageRatio: imageRatioOld,
            gallery_imageMode: imageModeOld,
            gallery_ribbonPlacement: ribbonPlacement,
          },
        },
        textsMap: {digitalProductBadgeAriaLabelText},
        experiments: {
          isAllowGalleryRoundCornersInViewer,
          newUiTpaImage,
          isUpliftGalleryRibbonFrontEnabled,
          shouldUseGalleryMobileSettingsInViewer,
        },
      },
      product,
    } = this.props;

    const productWithCurrentMedia = this.shouldShowProductOptions
      ? {
          ...product,
          media: this.currentMedia,
        }
      : product;

    const ImageComponent = newUiTpaImage ? ProductMedia : ProductImageOLD;
    const shouldRenderRibbonOnImage =
      !isUpliftGalleryRibbonFrontEnabled || ribbonPlacement === RibbonPlacementId.OnImage;

    return (
      <ImageComponent
        classNames={{thumbnail: this.s.productThumbnail, image: this.s.productImage}}
        product={productWithCurrentMedia}
        isMobile={shouldShowMobile}
        hoverType={hoverType}
        imageRatioId={shouldUseGalleryMobileSettingsInViewer ? imageRatio : imageRatioOld}
        imageModeId={shouldUseGalleryMobileSettingsInViewer ? imageMode : imageModeOld}
        textsMap={{digitalProductBadgeAriaLabel: digitalProductBadgeAriaLabelText}}
        isAllowGalleryRoundCornersInViewer={isAllowGalleryRoundCornersInViewer}>
        {shouldRenderRibbonOnImage ? this.renderRibbon() : null}
        {!shouldShowMobile && shouldShowQuickViewButton && this.renderQuickViewButton()}
      </ImageComponent>
    );
  }

  private renderPrice() {
    const {
      globals: {
        allowFreeProducts,
        textsMap: {
          productPriceBeforeDiscountSR,
          productOutOfStockText,
          productPriceAfterDiscountSR,
          productPriceWhenThereIsNoDiscountSR,
          measurementUnits,
          pricePerUnitSR,
        },
        experiments: {shouldUseGalleryMobileSettingsInViewer},
      },
      product,
    } = this.props;

    /* istanbul ignore next: todo tests @titk */
    const productWithPriceInfo = this.shouldShowProductOptions
      ? {
          ...product,
          ...this.productVariantInfo /* istanbul ignore next: todo tests @titk */?.priceInfo,
        }
      : product;

    return (
      <ProductPrice
        product={productWithPriceInfo}
        isAddtoCartButtonExists={this.props.globals.shouldShowAddToCartButton}
        allowFreeProducts={allowFreeProducts}
        textsMap={{
          productPriceBeforeDiscountSR,
          productOutOfStockText,
          productPriceAfterDiscountSR,
          productPriceWhenThereIsNoDiscountSR,
          measurementUnits,
          pricePerUnitSR,
        }}
        fromPrice={this.productPriceRange}
        priceBreakdown={this.props.globals.priceBreakdown}
        isRTL={this.props.globals.isRTL}
        isMobile={this.props.globals.shouldShowMobile}
        sendClickShippingInfoLinkSf={this.props.globals.sendClickShippingInfoLinkSf}
        shouldApplyMobileSettings={shouldUseGalleryMobileSettingsInViewer}
      />
    );
  }

  private renderLineBetweenNameAndPrice() {
    return (
      <div>
        <hr data-hook={DataHook.LineBetweenNameAndPrice} className={this.s.productDivider} aria-hidden="true" />
      </div>
    );
  }

  private renderQuickViewButton() {
    return (
      <button
        className={this.s.quickViewButton}
        data-hook={DataHook.QuickViewButton}
        tabIndex={-1}
        aria-hidden="true"
        onClick={this.handleQuickViewButtonClick}>
        {this.props.globals.textsMap.quickViewButtonText}
      </button>
    );
  }

  private renderRibbon() {
    const {
      product: {ribbon},
      globals: {
        shouldShowMobile,
        experiments: {
          isHideRibbonAtGalleryFrontEnabled,
          isUpliftGalleryRibbonFrontEnabled,
          shouldUseGalleryMobileSettingsInViewer,
        },
        styleParams: {
          numbers: {gallery_ribbonPlacement: ribbonPlacement, gallery_ribbonType: ribbonType},
          booleans: {gallery_showRibbon: shouldShowRibbon},
        },
      },
    } = this.props;

    if (isHideRibbonAtGalleryFrontEnabled && !shouldShowRibbon) {
      return null;
    }

    if (isUpliftGalleryRibbonFrontEnabled && ribbon) {
      return <ProductRibbon ribbon={ribbon} placement={ribbonPlacement} type={ribbonType} />;
    } else if (ribbon) {
      return (
        <span
          className={classNames(this.s.ribbon, {
            [this.s.mobile]: shouldUseGalleryMobileSettingsInViewer && shouldShowMobile,
          })}
          data-hook={DataHook.Ribbon}>
          {ribbon}
        </span>
      );
    }

    return null;
  }

  private handleQuickViewButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    const {
      globals: {openQuickView},
      product: {id: productId},
      index,
    } = this.props;
    const {quantity} = this.state;
    const selectionIds = this.productVariantInfo?.variantSelectionIds || [];

    event.preventDefault();
    event.stopPropagation();

    openQuickView({productId, index, selectionIds, quantity});
  }

  private handleAddToCartButtonClick() {
    const {
      globals: {handleAddToCart},
      product: {id: productId},
      index,
    } = this.props;

    const {quantity} = this.state;

    handleAddToCart({productId, index, quantity});
    this.setState({quantity: 1});
  }

  private handleQuantityCounterChange(val: string) {
    const {t} = this.props;
    const {min, max} = this.state.limits.quantity;
    const quantity = parseInt(val, 10) || 0;

    const minimumError = quantity < min && t('quantityMinimumAmountSR', {minimum: min});
    const maximumError = quantity > max && t('quantityMaximumAmountSR', {inventory: max});

    this.setState(
      ({errors}) => {
        const nextErrors = {...errors, quantity: minimumError || maximumError};
        return {quantity, errors: nextErrors};
      },
      () => {
        if (this.state.errors.quantity) {
          this.props.a11yAnnouncer.announce(this.state.errors.quantity);
        } else {
          this.props.a11yAnnouncer.announce(
            this.props.t('quantityTotalSR', {
              quantity,
            })
          );
        }
      }
    );
  }

  private readonly handleSelectionsChange = (selections: number[], data: ProductOptionsChangeData) =>
    this.setState(
      {
        selections,
      },
      () => {
        const {
          props: {
            globals: {handleProductsOptionsChange},
            product: {id: productId},
          },
        } = this;

        const {optionType} = data;

        handleProductsOptionsChange({
          productId,
          selectionIds: selections,
          optionType,
        });
      }
    );

  private handleProductItemClick(event: React.MouseEvent<HTMLAnchorElement>) {
    const {
      globals: {handleProductItemClick},
      product: {id: productId},
      index,
      disabled,
    } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (disabled) {
      return;
    }
    handleProductItemClick({
      biData: {
        productId,
        index,
      },
    });
  }

  public componentDidUpdate(prevProps: Readonly<IProductItemProps>): void {
    const {
      product,
      globals: {
        styleParams: {
          fonts: {
            gallery_hoverType: {value: previousHoverType},
          },
        },
        addedToCartStatus: prevAddedToCartStatus,
      },
    } = prevProps;
    const {
      globals: {
        styleParams: {
          fonts: {
            gallery_hoverType: {value: currentHoverType},
          },
        },
        addedToCartStatus,
        shouldShowAddToCartSuccessAnimation,
      },
      a11yAnnouncer,
      t,
    } = this.props;

    if (previousHoverType !== currentHoverType) {
      this.setState({showHoverPreview: true});

      this.debouncedStopHoverPreview();
    }

    const prevAddToCartStatus = prevAddedToCartStatus[product.id];
    const addToCartStatus = addedToCartStatus[product.id];

    if (
      shouldShowAddToCartSuccessAnimation &&
      prevAddToCartStatus !== ActionStatus.SUCCESSFUL &&
      addToCartStatus === ActionStatus.SUCCESSFUL
    ) {
      a11yAnnouncer.announce(t('addToCartSuccessSR', {productName: product.name}));
    }
  }

  private get isAddToCartEnabled() {
    const {
      globals: {
        experiments: {isPreOrderEnabled},
      },
    } = this.props;
    const currentState = this.props.globals.productsManifest[this.props.product.id].addToCartState;
    const isEnabledState = currentState === AddToCartState.ENABLED;
    const isPreOrderState = isPreOrderEnabled && currentState === AddToCartState.PRE_ORDER;
    return isEnabledState || isPreOrderState;
  }

  private get shouldRenderQuantityCounter() {
    const {
      globals: {shouldShowAddToCartButton, shouldShowQuantity},
    } = this.props;

    const hasOptions = this.props.product.options.length > 0;
    const hasSingleOption =
      this.props.product.options.length === 1 ||
      this.props.product.options.every(({optionType}) => optionType === ProductOptionType.COLOR);

    return (
      shouldShowAddToCartButton &&
      shouldShowQuantity &&
      this.isAddToCartEnabled &&
      (!hasOptions || (this.shouldShowProductOptions && hasSingleOption))
    );
  }

  private get shouldShowProductOptions() {
    const {
      globals: {shouldShowProductOptions},
      product,
    } = this.props;
    return shouldShowProductOptions && product.isInStock;
  }

  private get productVariantInfo() {
    const {
      globals: {productsVariantInfoMap},
      product,
    } = this.props;

    return productsVariantInfoMap?.[product.id];
  }

  private get productPriceRange(): string | undefined {
    const {
      globals: {productsPriceRangeMap},
      product,
    } = this.props;

    return productsPriceRangeMap?.[product.id];
  }

  private readonly getRevealClassNames = () => {
    if (this.props.globals.isOptionsRevealEnabled) {
      const hasOptions = this.props.product.options.length > 0;
      const hasSingleOption =
        this.props.product.options.length === 1 ||
        this.props.product.options.every(({optionType}) => optionType === ProductOptionType.COLOR);
      const hasSelections = this.productVariantInfo.variantSelectionIds.length > 0;
      const shouldBeHidden = hasOptions && hasSingleOption && !hasSelections;
      return {
        [this.s.hiddenModeHidden]: shouldBeHidden,
        [this.s.hiddenModeVisible]: !shouldBeHidden,
      };
    }
    return {};
  };

  private readonly renderQuantityCounter = () => {
    const {
      globals: {
        shouldShowMobile,
        styleParams: {
          booleans: {gallery_addToCartButtonShowOnHover: shouldShowAddToCartButtonShowOnHover},
        },
        textsMap,
      },
    } = this.props;

    const {
      quantity,
      limits: {
        quantity: {min, max},
      },
    } = this.state;

    const errors = {
      error: !!this.state.errors.quantity,
      errorMessage: this.state.errors.quantity,
    };

    return (
      <div
        className={classNames(this.s.quantity, this.getRevealClassNames())}
        data-hook={DataHook.QuantityCounterWrapper}>
        <Counter
          {...errors}
          decrementAriaLabel={textsMap.quantityRemoveSR}
          incrementAriaLabel={textsMap.quantityAddSR}
          aria-label={textsMap.quantityChooseAmountSR}
          inputAriaLabel={textsMap.quantityInputSR}
          onChange={this.handleQuantityCounterChange}
          data-hook={DataHook.QuantityCounter}
          value={quantity}
          min={min}
          max={max}
          className={classNames(
            styleQuantity(quantityStyle.quantityCounter, {
              customized: true,
            }),
            {
              [this.s.showOnHover]: shouldShowAddToCartButtonShowOnHover && !shouldShowMobile,
            }
          )}
        />
      </div>
    );
  };

  public renderOptions = () => {
    const {product} = this.props;

    return (
      <div data-hook={DataHook.Options}>
        <ProductOptionsWithGlobals
          variantsAvailability={this.productVariantInfo.selectionsAvailability}
          onSelectionIdsChange={this.handleSelectionsChange}
          product={product}
          selectionIds={this.productVariantInfo.variantSelectionIds}
          isItemHovered={this.state.isHovered}
        />
      </div>
    );
  };

  private get addToCartText(): string {
    const {
      globals: {
        experiments: {isPreOrderEnabled},
      },
    } = this.props;
    const {
      galleryAddToCartButtonText,
      addToCartContactSeller,
      addToCartOutOfStock,
      galleryAddToCartPreOrderButtonText,
    } = this.props.globals.textsMap;
    const {addToCartState} = this.props.globals.productsManifest[this.props.product.id];

    if (addToCartState === AddToCartState.DISABLED) {
      return addToCartContactSeller;
    }
    if (addToCartState === AddToCartState.OUT_OF_STOCK) {
      return addToCartOutOfStock;
    }
    if (isPreOrderEnabled && addToCartState === AddToCartState.PRE_ORDER) {
      return galleryAddToCartPreOrderButtonText;
    }
    return galleryAddToCartButtonText;
  }

  private get currentMedia() {
    const {product} = this.props;

    return this.productVariantInfo?.mediaItems ?? product.media;
  }

  private get verticalAlignmentClass() {
    const {gallery_verticalAlignment} = this.props.globals.styleParams.fonts;
    switch (gallery_verticalAlignment?.value) {
      case VerticalAlignments.TOP:
        return this.s.verticalAlignmentTop;
      case VerticalAlignments.BOTTOM:
        return this.s.verticalAlignmentBottom;
    }
  }

  private get addToCartAnimationState(): StatesButtonStates {
    const {addedToCartStatus, shouldShowAddToCartSuccessAnimation} = this.props.globals;
    const isAddedSuccessfully = addedToCartStatus[this.props.product.id] === ActionStatus.SUCCESSFUL;
    if (shouldShowAddToCartSuccessAnimation && isAddedSuccessfully) {
      return StatesButtonStates.SUCCESS;
    }
    return StatesButtonStates.IDLE;
  }

  public onAddToCartClicked = () => {
    this.setState({quantity: 1});
  };

  public renderAddToCartButton = () => {
    const {product, index} = this.props;
    const {quantity} = this.state;

    const {
      shouldShowMobile,
      experiments: {shouldUseGalleryMobileSettingsInViewer, isUpliftGalleryAtcViewerEnabled},
      styleParams: {
        booleans: {gallery_addToCartButtonShowOnHover: shouldShowOnHover},
      },
    } = this.props.globals;

    if (isUpliftGalleryAtcViewerEnabled) {
      return (
        <AddToCart
          product={product}
          index={index}
          quantity={quantity}
          onAddToCartClicked={this.onAddToCartClicked}
          showOnHoverClassName={this.s.showOnHover}
        />
      );
    }

    return (
      <div className={this.s.addToCartBtnContainer}>
        <StatesButton
          state={this.addToCartAnimationState}
          idleContent={this.addToCartText}
          onNotificationEnd={this.handleNotificationEnd}
          ref={this.addToCartButtonRef}
          onClick={this.handleAddToCartButtonClick}
          disabled={!this.isAddToCartEnabled}
          className={classNames(
            addToCartStyles(addToCartStyle.addToCartButton, {
              useMobileFont: shouldUseGalleryMobileSettingsInViewer && shouldShowMobile,
            }),
            {
              [this.s.showOnHover]: shouldShowOnHover && !shouldShowMobile,
            }
          )}
          fullWidth
          data-hook={DataHook.AddToCartButton}
        />
      </div>
    );
  };

  private readonly handleNotificationEnd = () => {
    this.props.globals.updateAddToCartStatus(this.props.product.id, ActionStatus.IDLE);
  };

  private get shouldShowImageOnRight() {
    const {
      index,
      globals: {isHorizontalLayout, imagePosition, shouldAlternateImagePosition},
    } = this.props;

    if (!isHorizontalLayout) {
      return false;
    }

    const oddProductItemIndex = index % 2;
    if (shouldAlternateImagePosition) {
      return imagePosition === ImagePositions.LEFT ? oddProductItemIndex : !oddProductItemIndex;
    }

    return imagePosition === ImagePositions.RIGHT;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public render() {
    const {
      globals: {
        isRTL,
        shouldShowMobile,
        isLiveSiteMode,
        isPreviewMode,
        isHorizontalLayout,
        productsManifest,
        shouldShowAddToCartButton,
        experiments: {
          isAllowGalleryProductRoundCornersInViewer,
          galleryProductItemNewComposition,
          isUpliftGalleryRibbonFrontEnabled,
        },
        styleParams: {
          booleans: {
            gallery_showPrice: shouldShowPrice,
            gallery_showProductName: shouldShowName,
            gallery_showDividers: shouldShowLineBetweenNameAndPrice,
          },
          fonts: {
            gallery_hoverType: {value: hoverType},
            gallery_alignment: {value: alignment},
          },
          numbers: {gallery_ribbonPlacement: ribbonPlacement},
        },
      },
      product,
      style,
    } = this.props;
    if (!productsManifest[product.id]) {
      return null;
    }

    const {showHoverPreview} = this.state;
    const shouldShowProductDetails = shouldShowName || shouldShowLineBetweenNameAndPrice || shouldShowPrice;
    const productPageUrl = productsManifest[product.id].url;
    const shouldRenderRibbonInProductInfo =
      isUpliftGalleryRibbonFrontEnabled && ribbonPlacement === RibbonPlacementId.ProductInfo;
    const shouldAlignStart = (alignment === Alignments.LEFT && !isRTL) || (alignment === Alignments.RIGHT && isRTL);
    const shouldAlignEnd = (alignment === Alignments.RIGHT && !isRTL) || (alignment === Alignments.LEFT && isRTL);

    const newRoot = (
      <div
        onMouseEnter={() => this.setState({isHovered: true})}
        onMouseLeave={() => this.setState({isHovered: false})}
        style={style}
        data-hook={DataHook.Root}
        className={classNames(this.s.productItem, shouldShowMobile ? this.s.none : this.s[hoverType], {
          [this.s.hoverPreview]: showHoverPreview,
          [this.s.productOptions]: this.shouldShowProductOptions,
          [this.s.roundCorners]: isAllowGalleryProductRoundCornersInViewer,
          [this.s.horizontalLayout]: isHorizontalLayout,
          [this.s.imageOnRight]: this.shouldShowImageOnRight,
          [this.s.isRTL]: isRTL,
        })}>
        <a
          href={isLiveSiteMode || isPreviewMode ? productPageUrl : null}
          tabIndex={-1}
          onClick={this.handleProductItemClick}
          className={this.s.productItemLink}
          data-hook={DataHook.LinkContainer}>
          {this.renderImage()}
        </a>
        <div
          data-hook={DataHook.NotImageContainer}
          className={classNames(this.s.notImageContainer, {
            [this.verticalAlignmentClass]: isHorizontalLayout,
          })}>
          <a
            href={isLiveSiteMode || isPreviewMode ? productPageUrl : null}
            onClick={this.handleProductItemClick}
            className={this.s.productDetailsLink}
            data-hook={DataHook.ProductDetailsLink}
            ref={this.productLink}>
            {!shouldShowName && this.renderSrOnlyName()}
            {shouldShowProductDetails && (
              <div
                className={classNames(
                  this.s.productDetails,
                  {[this.s.alignCenter]: alignment === Alignments.CENTER},
                  {[this.s.alignStart]: shouldAlignStart},
                  {[this.s.alignEnd]: shouldAlignEnd}
                )}
                data-hook={DataHook.ProductDetails}>
                {shouldRenderRibbonInProductInfo ? this.renderRibbon() : null}
                {shouldShowName && this.renderName()}
                {shouldShowLineBetweenNameAndPrice && this.renderLineBetweenNameAndPrice()}
                {shouldShowPrice && this.renderPrice()}
              </div>
            )}
          </a>

          {this.shouldShowProductOptions && (
            <div className={this.s.productInputs}>
              {this.renderOptions()}
              {this.shouldRenderQuantityCounter && this.renderQuantityCounter()}
            </div>
          )}

          {shouldShowAddToCartButton && (
            <div className={this.s.productFooter}>
              {this.shouldRenderQuantityCounter && !this.shouldShowProductOptions && this.renderQuantityCounter()}
              {this.renderAddToCartButton()}
            </div>
          )}
        </div>
      </div>
    );

    const oldRoot = (
      <div
        onMouseEnter={() => this.setState({isHovered: true})}
        onMouseLeave={() => this.setState({isHovered: false})}
        style={style}
        data-hook={DataHook.Root}
        className={classNames(this.s.productItem, shouldShowMobile ? this.s.none : this.s[hoverType], {
          [this.s.hoverPreview]: showHoverPreview,
          [this.s.productOptions]: this.shouldShowProductOptions,
          [this.s.roundCorners]: isAllowGalleryProductRoundCornersInViewer,
        })}>
        <a
          href={isLiveSiteMode || isPreviewMode ? productPageUrl : null}
          onClick={this.handleProductItemClick}
          className={this.s.productItemLink}
          data-hook={DataHook.LinkContainer}
          ref={this.productLink}>
          {this.renderImage()}
          {!shouldShowName && this.renderSrOnlyName()}
          {shouldShowProductDetails && (
            <div
              className={classNames(
                this.s.productDetails,
                {[this.s.alignCenter]: alignment === Alignments.CENTER},
                {[this.s.alignStart]: shouldAlignStart},
                {[this.s.alignEnd]: shouldAlignEnd}
              )}
              data-hook={DataHook.ProductDetails}>
              {shouldRenderRibbonInProductInfo ? this.renderRibbon() : null}
              {shouldShowName && this.renderName()}
              {shouldShowLineBetweenNameAndPrice && this.renderLineBetweenNameAndPrice()}
              {shouldShowPrice && this.renderPrice()}
            </div>
          )}
        </a>

        {this.shouldShowProductOptions && (
          <div className={this.s.productInputs}>
            {this.renderOptions()}
            {this.shouldRenderQuantityCounter && this.renderQuantityCounter()}
          </div>
        )}

        {shouldShowAddToCartButton && (
          <div className={this.s.productFooter}>
            {this.shouldRenderQuantityCounter && !this.shouldShowProductOptions && this.renderQuantityCounter()}
            {this.renderAddToCartButton()}
          </div>
        )}
      </div>
    );

    return galleryProductItemNewComposition ? newRoot : oldRoot;
  }
}

export const ProductItemWithGlobals = withGlobals(withTranslations()(ProductItem));
