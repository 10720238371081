import * as React from 'react';
import {CSSProperties, ReactNode, RefObject} from 'react';
import * as s from './Carousel.scss';
import {Arrow, ArrowDirection} from './Arrow/Arrow';

interface CarouselState {
  style?: CSSProperties;
  items?: ReactNode[];
}

export enum CarouselDataHooks {
  PreviousArrow = 'left-arrow',
  NextArrow = 'right-arrow',
  Carousel = 'carousel',
}

export const TRANSITION_TIME = 500;

export class Carousel extends React.Component<{}, CarouselState> {
  private readonly ref: RefObject<any>;
  private isElementsOverFlow: boolean;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.isElementsOverFlow = false;
    this.state = {
      style: {},
      items: React.Children.toArray(this.props.children),
    };
  }

  private readonly containerWidth = () => {
    return this.ref.current?.getBoundingClientRect().width;
  };

  private readonly spinLeft = () => {
    const style: CSSProperties = {};
    style.transition = `transform ${TRANSITION_TIME}ms ease-in-out`;
    style.transform = `translateX(0)`;

    this.isElementsOverFlow = false;

    return this.setState({style});
  };

  private readonly spinRight = () => {
    const style: CSSProperties = {};
    style.transition = `transform ${TRANSITION_TIME}ms ease-in-out`;
    style.transform = `translateX(${-this.containerWidth()}px)`;
    this.isElementsOverFlow = true;

    this.setState({style});
  };

  private readonly onNext = () => {
    const nextItems = this.state.items;

    if (this.isElementsOverFlow) {
      const style: CSSProperties = {};
      style.transition = '';
      style.transform = `translateX(0)`;
      const firstItem = nextItems.shift();
      nextItems.push(firstItem);
      this.isElementsOverFlow = false;
      this.setState({style, items: nextItems}, this.spinRight);
    } else {
      this.spinRight();
    }
  };

  private readonly onPrevious = () => {
    if (this.isElementsOverFlow) {
      this.spinLeft();
    } else {
      const style: CSSProperties = {};
      const nextItems = this.state.items;

      style.transition = '';
      style.transform = `translateX(${-this.containerWidth()}px)`;
      const firstItem = nextItems.pop();
      nextItems.unshift(firstItem);
      this.isElementsOverFlow = true;
      this.setState({style, items: nextItems});
      setTimeout(this.spinLeft, 0);
    }
  };

  public render() {
    return (
      <div ref={this.ref} className={s.carouselWrapper}>
        <Arrow
          dataHook={CarouselDataHooks.PreviousArrow}
          onClick={this.onPrevious}
          direction={ArrowDirection.LEFT}
          className={s.arrow}
        />
        <div className={s.carousel} data-hook={CarouselDataHooks.Carousel} style={this.state.style}>
          {this.state.items}
        </div>
        <Arrow
          dataHook={CarouselDataHooks.NextArrow}
          onClick={this.onNext}
          direction={ArrowDirection.RIGHT}
          className={s.arrow}
        />
      </div>
    );
  }
}
