import React from 'react';
import s from './Ribbon.scss';
import {DataHook} from '../ProductItem';
import {RibbonPlacementId, RibbonType} from '../../../../types/galleryTypes';
import classNames from 'classnames';
import {withGlobals} from '../../../../globalPropsContext';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';

export interface IRibbonProps extends IGalleryGlobalProps {
  ribbon: string;
  placement: RibbonPlacementId;
  type: RibbonType;
}

export class RibbonComp extends React.Component<IRibbonProps> {
  public render() {
    const {
      ribbon,
      placement,
      type,
      globals: {
        shouldShowMobile,
        experiments: {shouldUseGalleryMobileSettingsInViewer},
      },
    } = this.props;
    return (
      <span
        className={classNames(
          s.ribbon,
          {[s.onImage]: placement === RibbonPlacementId.OnImage},
          {[s.productInfo]: placement === RibbonPlacementId.ProductInfo},
          {[s.rectangle]: type === RibbonType.RECTANGLE},
          {[s.mobile]: shouldUseGalleryMobileSettingsInViewer && shouldShowMobile}
        )}
        data-hook={DataHook.RibbonNew}>
        {ribbon}
      </span>
    );
  }
}

export const Ribbon = withGlobals(withTranslations()(RibbonComp));
